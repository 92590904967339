<template>
  <div class="main_content">
  </div>
</template>

<script>
export default {
  name: "home",
  mounted() {
    //document.body.innerHTML = "<iframe style=\"width: 100%; height:1000px\" src=\"https://www.esporteclubebahia.com.br/\"/>";
    window.location = "https://esporteclubebahia.com.br";
  },
};
</script>

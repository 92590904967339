import Vue from 'vue'
import Vuetify from 'vuetify';
import router from './router'
import App from './App.vue'

Vue.use(Vuetify);
Vue.config.productionTip = location.hostname === 'localhost';
Vue.directive('focus', {
    inserted: function (el) {
        el.focus()
    }
});

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')

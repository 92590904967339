import Vue from 'vue'
import Router from 'vue-router'
import Home from './views/Home.vue'
import ConfirmEMail from './views/ConfirmEMail.vue'
import UpdatePassword from './views/UpdatePassword.vue'

Vue.use(Router);

export default new Router({
  routes: [
    {
      path: '/confirmar/email/:token',
      name: 'confirmarEMail',
      component: ConfirmEMail,
      props: true,
    },
    {
      path: '/alterar/senha/:token',
      name: 'alterarSenha',
      component: UpdatePassword,
      props: true,
    },
    {
      path: '/*',
      name: 'home',
      component: Home
    },
    // },
    // {
    //   path: '/about',
    //   name: 'about',
    //   // route level code-splitting
    //   // this generates a separate chunk (about.[hash].js) for this route
    //   // which is lazy-loaded when the route is visited.
    //   component: () => import(/* _webpackChunkName: "about" */ './views/About.vue')
    // }
  ]
})

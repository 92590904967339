<template>
  <center>
    <div class="background">
      <img src="https://cdn.bbmp.app/background.png" />
    </div>
    <div class="row">
      <div id="logo_footer">
        <img
          style="aligh: center"
          width="70"
          height="80"
          class="visible-xs"
          src="https://www.esporteclubebahia.com.br/wp-content/themes/2016/img/mobile/logo_marca_footer.png" />
      </div>
    </div>
    <form>
      <h3>BBMP - Redefinição de Senha</h3>
      <p></p>

      <v-text-field
        v-focus
        :disabled="successResult"
        type="password"
        label="Nova Senha"
        :error-messages="newPasswordErrors"
        v-model="newPassword"
      />

      <v-text-field
        :disabled="successResult"
        type="password"
        label="Confirmar a nova senha"
        :error-messages="repeatedPasswordErrors"
        v-model="repeatedPassword"
      />

      <v-alert
        :value="message !== ''"
        :color="hasErrorOnLasRequest ? 'error': 'success'"
        outline
      >{{ message }}</v-alert>


      <v-btn @click="submit" :loading="loading" :disabled="!isValid || successResult">Redefinir Senha</v-btn>
    </form>

    <div class="row">
      <div id="endereco_footer">
        Rua Antônio Fernandes, nº 0, Jardim das Margaridas, Salvador-BA<br/>
        CEP: 41502-590, CNPJ: 15.193.923/0001-84<br/>
        <a href="https://www.esporteclubebahia.com.br/" target="_blank">www.esporteclubebahia.com.br</a>
      </div>
    </div>
  </center>
</template>

<script>
import axios from "axios";
import "vuetify/dist/vuetify.min.css";

const axiosInstance = axios.create({
  baseURL: "https://api.bbmp.app/"
});
export default {
  name: "UpdatePassword",
  props: ["token"],
  data: () => ({
    hasErrorOnLasRequest: false,
    loading: false,
    newPassword: "",
    repeatedPassword: "",
    message: ""
  }),
  computed: {
    isValid() {
      return (
        !!this.newPassword &&
        !!this.repeatedPassword &&
        this.newPassword === this.repeatedPassword
      );
    },
    newPasswordErrors() {
      const errors = [];
      if (!this.newPassword) {
        errors.push("Informe a nova senha");
      }
      if (this.newPassword.length < 6) {
        errors.push("Mínimo 6 caracteres");
      }
      return errors;
    },
    repeatedPasswordErrors() {
      const errors = [];
      if (this.newPassword !== this.repeatedPassword) {
        errors.push("As duas senhas devem ser iguais");
      }

      return errors;
    }
  },
  mounted() {
  },
  methods: {
    reset() {
      this.newPassword = "";
      this.repeatedPassword = "";
    },
    error() {
      this.hasErrorOnLasRequest = true;
      this.loading = false;
      this.showMessage("Não foi possível alterar sua senha. Por favor tente criar uma nova solicitação de recuperação de senha no aplicativo.");
    },
    showMessage(result) {
      this.message = result;
      this.loading = false;
    },
    success() {
      this.hasErrorOnLasRequest = false;
      this.successResult = true;
      //this.reset();
      this.showMessage("Sua senha foi redefinida com sucesso.");
      //setTimeout(() =>  location = "https://esporteclubebahia.com.br", 2000);
    },
    submit() {
      this.loading = true;
      this.message = "";
      axiosInstance
        .post("/internal/updateMyPassword", {
          token: this.token,
          newPassword: this.newPassword,
          confirmation: this.repeatedPassword
        })
        .then(this.success)
        .catch(this.error);
    }
  }
};
</script>

<style>
form {

} .background {

  object-fit: cover;
  overflow: hidden;
  z-index: 1;
  position: absolute;
  z-index: -1;
  left: 0;
  top: 0;

} .background img {

  width: 100vw;
  height: 100vh;

}
#endereco_footer {
  color: #858585;
  font-size: 11px;
  margin-top: 16px;
  line-height: 14px;
}
.error--text {
  max-width: 312px;
  color: red !important;
}
.success--text {
  max-width: 312px;
  color: green !important;
}
.error--text .v-messages__wrapper .v-messages__message {
  max-width: 312px;
  color: red;
}
</style>

<template>
  <center>
    <div class="background">
      <img src="https://cdn.bbmp.app/background.png" />
    </div>
    <div class="row">
      <div id="logo_footer">
        <img
          style="aligh: center"
          width="70"
          height="80"
          class="visible-xs"
          src="https://www.esporteclubebahia.com.br/wp-content/themes/2016/img/mobile/logo_marca_footer.png" />
      </div>
    </div>
    <form>
      <h3>BBMP - Confirmação de E-mail</h3>
      <p></p>
      <v-alert
        :value="message !== ''"
        :color="hasErrorOnLasRequest ? 'error': 'success'"
        outline
      >{{ message }}</v-alert>
    </form>
    <div class="row">
      <div id="endereco_footer">
        Rua Antônio Fernandes, nº 0, Jardim das Margaridas, Salvador-BA<br/>
        CEP: 41502-590, CNPJ: 15.193.923/0001-84<br/>
        <a href="https://www.esporteclubebahia.com.br/" target="_blank">www.esporteclubebahia.com.br</a>
      </div>
    </div>
  </center>
</template>

<script>
import axios from "axios";
import "vuetify/dist/vuetify.min.css";
const axiosInstance = axios.create({
  baseURL: "https://api.bbmp.app/"
});
export default {
  name: "ConfirmEMail",
  props: ["token"],
  data: () => ({
    hasErrorOnLasRequest: false,
    loading: false,
    message: ""
  }),
   mounted() {
     this.submit();
  },
  methods: {
    error() {
      this.hasErrorOnLasRequest = true;
      this.loading = false;
      this.showMessage("Link de confirmação inválido. Certifique-se que o link de acesso para confirmação ainda não foi utilizado e que está correto.");
    },
    showMessage(result) {
      this.message = result;
      this.loading = false;
    },
    success() {
      this.hasErrorOnLasRequest = false;
      this.showMessage("Seu e-mail foi confirmado com sucesso.");
    },
    submit() {
      this.loading = true;
      this.message = "";
      axiosInstance
        .post("/internal/confirmEMail", {
          token: this.token
        })
        .then(this.success)
        .catch(this.error);
    }
  }
};
</script>

<style>
form {
  
} .background {

  object-fit: cover;
  overflow: hidden;
  z-index: 1;
  position: absolute;
  z-index: -1;
  left: 0;
  top: 0;

} .background img {

  width: 100vw;
  height: 100vh;

}
#endereco_footer {
  color: #858585;
  font-size: 11px;
  margin-top: 16px;
  line-height: 14px;
}
.error--text {
  max-width: 312px;
  color: red !important;
}
.success--text {
  max-width: 312px;
  color: green !important;
}
.error--text .v-messages__wrapper .v-messages__message {
  max-width: 312px;
  color: red;
}
</style>
